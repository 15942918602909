import styled, {css} from 'styled-components';

import {
    Theme,
    RootContainerStyle
} from "../globalStyles";

export const Container = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContactContainer = styled(RootContainerStyle)`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    div{
        width: 100%;
        height: 230px;
        border-radius: ${Theme.borderRadius};
        background-image: url("${props => props.ImageUrl}");
        background-position: center center;
        background-size: cover;
    }
    title{
        display: block;
        width: 100%;
        font-size: 22px;
        font-weight: bold;
        margin-top: 20px;
        color: ${Theme.accent1};
        ::after{
            display: block;
            content: "";
            width: 80px;
            height: 3px;
            background-color: ${Theme.accent3};
            margin-top: 13px;
        }
    }
    sub{
        display: inline-flex;
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
        article{
            width: 49%;
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (max-width: 720px){
        sub{
            display: flex;
            flex-direction: column;
            article{
                width: 100%;
            }
        }
    }
`;

export const ContactInputsLabel = styled.span`
    display: block;
    width: fit-content;
    color: ${Theme.accent1};
    font-size: 19px;
    font-weight: 500;
    opacity: 0.75;
    margin-top: 25px;
    margin-bottom: 10px;
`;

export const ContactInputs = styled.input`
    width: 100%;
    height: 55px;
    border: ${Theme.accent1};
    color: rgba(255,255,255, 0.5);    
    border-radius: ${Theme.borderRadius};
    background-color: ${Theme.background};
    border: 4px solid ${props => props.empty ? Theme.red : Theme.accent2};
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 17px;
    transition: 0.3s;
    ::placeholder{
        color: ${Theme.accent1};   
        font-weight: 300;
        font-size: 17px;
        opacity: 0.5; 
    }
`;

export const ContactTextArea = styled.textarea`
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    border: ${Theme.accent1};
    color: rgba(255,255,255, 0.5);    
    border-radius: ${Theme.borderRadius};
    background-color: ${Theme.background};
    border: 4px solid ${props => props.empty ? Theme.red : Theme.accent2};
    padding: 1rem;
    font-size: 17px;
    transition: 0.3s;
    ::placeholder{
        color: ${Theme.accent1};   
        font-weight: 300;
        font-size: 17px;
        opacity: 0.5; 
    }
`;

export const ContactSubmitButton = styled.button`
    width: 100%;
    min-height: 55px;
    max-height: 55px;
    background-color: ${props => props.error ? Theme.red : Theme.accent3};
    color: ${Theme.accent1};
    font-weight: 600;
    font-size: 18px;
    border-radius: ${Theme.borderRadius};
    transition: 0.4s;
    border: none;
    margin-top: 10px;
    :hover{
        opacity: 0.8;
        cursor: pointer;
    }
`;

export const ResponseMessageContact = styled.div`
    width: fit-content;
    max-width: 90vw;
    height: fit-content;
    z-index: 95;
    padding: 0.7rem;
    background-color: ${Theme.accent2};
    border-radius: ${Theme.borderRadius};
    position: fixed;
    font-size: 18px;
    font-weight: 600;
    color: ${Theme.accent1};
    transition: 0.5s;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.7);
    color: ${props => props.isErrorResponse ? Theme.red : Theme.green};
    border: 2px solid ${props => props.isErrorResponse ? Theme.red : Theme.green};
    ${props => props.display ? css`
        opacity: 1;
        top: 80px;
    `:css`
        opacity: 1;
        top: -100vh;
    `}
`;