import React from "react";
import axios from "axios";

import {
    Container,
    ContactContainer,
    ContactInputs,
    ContactInputsLabel,
    ContactTextArea,
    ContactSubmitButton,
    ResponseMessageContact
} from "../styles/contact";

import {
    SocialMediaIconContainer,
    SocialMediaIcon
} from "../globalStyles";

import * as Image0 from "../images/Contact/PhoneAndBook.jpg";

import * as Github from "../images/Commons/github.svg";
import * as Instagram from "../images/Commons/instagram.svg";
import * as Linkedin from "../images/Commons/linkedin.svg";

var Timer;

class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name_input: "",
            email_input: "",
            subject_input: "",
            message_input: "",
            response_msg: "",
            name_input_empty_bool: false,
            email_input_empty_bool: false,
            subject_input_empty_bool: false,
            message_input_empty_bool: false,
            display_response_message: false,
            isErrorResponse: false,
        }
    }

    componentDidMount(){
      this.setState({
        name_input: "",
        email_input: "",
        subject_input: "",
        message_input: "",
        response_msg: "",
        name_input_empty_bool: false,
        email_input_empty_bool: false,
        subject_input_empty_bool: false,
        message_input_empty_bool: false,
        missing_input_or_error: false,
        display_response_message: false,
        isErrorResponse: false,
      });
    }

    showResponseMessage(message, isErrorResponse) {
      this.setState({
        response_msg: message,
        display_response_message: true,
        isErrorResponse: isErrorResponse
      });
      if(typeof window !== 'undefined' && window){
        window.clearTimeout(Timer);
        Timer = window.setTimeout(() => this.setState({
          display_response_message: false
        }), 6000);
      }
    }

    handleInput() {
      var values_to_check = [
        this.state.name_input,
        this.state.email_input,
        this.state.subject_input,
        this.state.message_input,
      ];

      var missing = values_to_check.some(content => {
        if(content === "" || content === undefined) {
          return true;
        }
        return false;
      });
      if(!missing) axios.post("https://mailer.victorgomez.dev/api/mailer/message", {
          senderName: this.state.name_input,
          senderEmail: this.state.email_input,
          msgSubject: this.state.subject_input,
          msgBody: this.state.message_input
        }).then(response => {
          if(response) this.showResponseMessage("Sucesso no envio da mensagem!", false);
          this.setState({missing_input_or_error: false});
        }).catch(error => {
          this.setState({missing_input_or_error: true});
          if(error.response) this.showResponseMessage("Error " + error.response.data.code + ":  " + error.response.data.msg, true);
          else this.showResponseMessage("Erro na comunicação com o servidor, verifique sua conexão!", true);
        });
      else {
        this.setState({missing_input_or_error: true});
        this.showResponseMessage("Todos os inputs devem ser preenchidos!", true);
      }
    }

    render(){
      return(
            <>  
                <Container>
                    <ResponseMessageContact 
                    display={this.state.display_response_message}
                    isErrorResponse={this.state.isErrorResponse}>
                        {this.state.response_msg}
                    </ResponseMessageContact>
                    <SocialMediaIconContainer>
                      <title>
                        Encontre me:
                      </title>
                      <sub>
                        <SocialMediaIcon
                          data-sal="slide-up"
                          data-sal-delay="200"
                          data-sal-easing="ease"
                          href="https://github.com/Vicg853"> 
                          <Github/>
                        </SocialMediaIcon>
                        <SocialMediaIcon
                          data-sal="slide-up"
                          data-sal-delay="200"
                          data-sal-easing="ease"
                          href="https://www.instagram.com/victor_g853/"> 
                          <Instagram/>
                        </SocialMediaIcon>
                        <SocialMediaIcon
                          data-sal="slide-up"
                          data-sal-delay="200"
                          data-sal-easing="ease"
                          href="https://www.linkedin.com/in/victor-rosa-gomez-15953a171/"> 
                          <Linkedin/>
                        </SocialMediaIcon>
                      </sub>
                    </SocialMediaIconContainer> 
                    <ContactContainer 
                    ImageUrl={Image0}>
                        <div
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-easing="ease"></div>
                        <title>Envie uma mensagem</title>
                        <sub>
                            <article
                            data-sal="slide-right"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                                <ContactInputsLabel>
                                  Nome:
                                </ContactInputsLabel>
                                <ContactInputs 
                                value={this.state.name_input}
                                onChange={(e) => e.target.value === "" ? this.setState({name_input: "", name_input_empty_bool: true}) 
                                : this.setState({name_input: e.target.value, name_input_empty_bool: false, missing_input_or_error: false})}
                                empty={this.state.name_input_empty_bool}
                                placeholder="Nome"
                                />
                                <ContactInputsLabel>
                                  E-mail:
                                </ContactInputsLabel>
                                <ContactInputs 
                                value={this.state.email_input}
                                onChange={(e) => e.target.value === "" ? this.setState({email_input: "", email_input_empty_bool: true}) 
                                : this.setState({email_input: e.target.value, email_input_empty_bool: false, missing_input_or_error: false})}
                                empty={this.state.email_input_empty_bool}
                                placeholder="Email"
                                />
                                <ContactInputsLabel>
                                  Assunto:
                                </ContactInputsLabel>
                                <ContactInputs 
                                value={this.state.subject_input}
                                onChange={(e) => e.target.value === "" ? this.setState({subject_input: "", subject_input_empty_bool: true}) 
                                : this.setState({subject_input: e.target.value, subject_input_empty_bool: false, missing_input_or_error: false})}
                                empty={this.state.subject_input_empty_bool}
                                placeholder="Assunto"
                                />
                            </article>
                            <article
                            data-sal="slide-left"
                            data-sal-delay="300"
                            data-sal-easing="ease">
                                <ContactInputsLabel>
                                  Mensagem:
                                </ContactInputsLabel>
                                <ContactTextArea 
                                value={this.state.message_input}
                                onChange={(e) => e.target.value === "" ? this.setState({message_input: "", message_input_empty_bool: true}) 
                                : this.setState({message_input: e.target.value, message_input_empty_bool: false, missing_input_or_error: false})}
                                empty={this.state.message_input_empty_bool}
                                placeholder="Era lindo dia ensolarado..."
                                />
                                <ContactSubmitButton
                                onClick={() => this.handleInput()}
                                error={this.state.missing_input_or_error}>
                                    Enviar (pode demorar um pouco...)
                                </ContactSubmitButton>
                            </article>
                        </sub>
                    </ContactContainer>
                </Container>  
            </>
        );
    }
}

export default Contact;
